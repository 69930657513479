import type { Note, NoteUpdateParams, Notes } from '~/composables/types/api/searchDiscover/product'
import type { ApiResponse } from '~/composables/types/api/apiResponse'
import getNotes from '~/composables/api/searchDiscover/productNotes/getNotes'
import getNote from '~/composables/api/searchDiscover/productNotes/getNote'
import updateNote from '~/composables/api/searchDiscover/productNotes/updateNote'
import deleteNote from '~/composables/api/searchDiscover/productNotes/deleteNote'

export default function () {
    const notes = ref<Notes>()
    const note = ref<Note | ApiResponse>()

    const loadNotes = async () => {
        notes.value = (await getNotes()) as Notes
    }

    const loadNote = async (noteId: string): Promise<Note | ApiResponse> => {
        note.value = await getNote(noteId)

        return note.value
    }

    const noteUpdate = async (noteId: string, newNote: string): Promise<Note> => {
        const param: NoteUpdateParams = {
            noteId: noteId,
            note: newNote,
        }

        return (await updateNote(param)) as Note
    }

    const noteDelete = async (noteId: string): Promise<void> => {
        await deleteNote(noteId)
    }

    return {
        loadNotes,
        loadNote,
        noteUpdate,
        noteDelete,
        notes: computed(() => notes.value?.attributes?.idsWithNotes ?? []),
    }
}
