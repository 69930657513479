import type { Notes, NotesResponse } from '~/composables/types/api/searchDiscover/product'
import handleError from '~/composables/api/handleResponse'
import type { ApiResponse } from '~/composables/types/api/apiResponse'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function (): Promise<Notes | ApiResponse> {
    const url = `/abstract-products-notes`
    const { fetchSdApi } = fetchOrderDetermination()

    try {
        const response = (await fetchSdApi(url)) as NotesResponse

        return {
            type: response.data?.[0].type,
            id: response.data?.[0].id,
            attributes: response.data?.[0].attributes,
        }
    } catch (error) {
        return handleError(error)
    }
}
