<template>
    <div class="note-container" :class="{ 'is-tileview': tileView }" data-testid="noteFormContainer">
        <div class="note-form" :class="{ 'is-focused': focused, 'is-disabled': loading }">
            <textarea
                ref="noteInput"
                v-model="noteText"
                class="note-input"
                rows="3"
                :maxlength="MAX_LENGTH"
                :placeholder="$t('Product.add_note')"
                data-testid="noteFormTextarea"
                @input="onInput"
                @focus="focused = true"
                @blur="focused = false"
            />
            <span class="note-limit"
                >{{ !tileView ? $t('Product.characters') : '' }} {{ noteText.trim().length }}/{{ MAX_LENGTH }}</span
            >
        </div>

        <div class="flex" :class="{ 'items-stretch': !tileView, 'items-center': tileView }">
            <div class="text-left">
                <div v-if="!tileView && note" class="note-created">
                    {{ $t('Product.last_modified') }}:
                    {{ note.lastModified.replace(' ', ', ') }}
                    <span v-if="note.lastEditor">{{ $t('Product.by') }} {{ note.lastEditor }}</span>
                </div>

                <AtomButton
                    v-else-if="tileView"
                    icon="trash-2"
                    size="m"
                    type="secondary"
                    @click="deleteModalOpen = true"
                />
            </div>
            <div class="flex-1 text-right note-status">
                <div v-if="tileView && note && !noteFeedback?.hasState()" class="note-created">
                    {{ $t('Product.last_modified') }}:<br />
                    {{ note.lastModified.replace(' ', ', ') }}
                    <div v-if="note.lastEditor">{{ note.lastEditor }}</div>
                </div>

                <MoleculeMatrixFeedback
                    ref="noteFeedback"
                    v-bind="requestStatus"
                    :loading-text="$t('Product.saving')"
                    :is-matrix-table="false"
                />
            </div>
        </div>

        <LazyAtomModal
            v-if="tileView"
            class="note-modal"
            :open="deleteModalOpen"
            :buttons="deleteModalButtons"
            @button-clicked="deleteModalButtonClicked"
            @update:open="deleteModalOpen = false"
        >
            <div class="note-modal__title-container">
                <div class="note-modal__title">
                    {{ $t('Product.delete_note') }}
                </div>
                <div v-if="note && note.lastModified" class="note-modal__last-modified">
                    <span>{{
                        `${$t('Product.created_edited')} ${note.lastModified} ${$t('Product.by')} ${note.lastEditor}`
                    }}</span>
                </div>
            </div>
            <div>
                <span class="note-modal__text">{{ $t('Product.delete_note_text') }}</span>
            </div>
        </LazyAtomModal>
    </div>
</template>

<script setup lang="ts">
import type { Note, ProductNoteData } from '~/composables/types/api/searchDiscover/product'
import type { ModalButton } from '~/composables/types/ui'
import type { Handler } from 'mitt'
import type { ApiResponse } from '~/composables/types/api/apiResponse'

const props = defineProps<{
    productAbstractId: string | number
    noteData?: ProductNoteData
    autoSave?: boolean
    tileView?: boolean
    isCartService?: boolean
}>()

const emit = defineEmits(['loaded', 'fullyDeleted'])

const { $t, $emitter } = useNuxtApp()
const { loadNotes, loadNote, notes, noteDelete, noteUpdate } = useNotes()
const notesStore = useNotesStore()
const toasts = useToasts()

const loading = ref(false)
const note: Ref<Note | null> = ref(null)
const noteInput = ref()
const noteFeedback = ref()
const noteText = ref('')
const focused = ref(false)
const requestStatus = ref({
    load: false,
    loadSuccess: false,
    loadErr: false,
    successText: '',
    failedText: '',
})
let lastNoteText = ''
let timeoutId: ReturnType<typeof setTimeout> | null

const deleteModalOpen = ref(false)
const deleteModalButtons: ModalButton[] = [
    {
        event: 'close',
        text: $t('General.cancel'),
        type: 'secondary',
    },
    {
        event: 'delete',
        text: $t('General.delete'),
        type: 'primary',
    },
]

const MAX_LENGTH = 140
const UPDATE_TIMEOUT = 500

const deleteNote = async (fullyDeleted?: boolean) => {
    if (note.value) {
        await noteDelete(`${props.productAbstractId}`)

        note.value = null
        notesStore.removeId(`${props.productAbstractId}`)
    }

    requestStatus.value.load = false
    if (!fullyDeleted) {
        requestStatus.value.successText = $t('Product.note_deleted')
        requestStatus.value.loadSuccess = true
    } else if (props.tileView) {
        toasts.add({
            type: 'success',
            headline: $t('Product.note_deleted'),
            text: $t('Product.note_deleted_text'),
        })
        emit('fullyDeleted')
    }
}

const saveNote = async () => {
    if (noteText.value.trim() === lastNoteText) {
        return undefined
    }

    const previouseText = lastNoteText

    requestStatus.value.load = true
    requestStatus.value.loadSuccess = false
    requestStatus.value.loadErr = false
    lastNoteText = noteText.value.trim()

    if (!lastNoteText.length) {
        await deleteNote()

        return true
    }

    const response = await noteUpdate(`${props.productAbstractId}`, lastNoteText)

    if (response?.isSuccessful) {
        if (!note.value) {
            note.value = response
        } else {
            note.value.note = response.note
            note.value.lastModified = response.lastModified
            note.value.lastEditor = response.lastEditor
        }
        requestStatus.value.load = false
        requestStatus.value.successText = $t('Product.note_is_saved')
        requestStatus.value.loadSuccess = true

        if (!notesStore.hasId(`${props.productAbstractId}`)) {
            notesStore.insert(`${props.productAbstractId}`)
        }

        if (!props.tileView) {
            $emitter.$emit(`updateProductNote_${props.productAbstractId}`, note.value)
        }

        return true
    }

    requestStatus.value.load = false
    requestStatus.value.failedText = $t('Product.note_not_saved')
    requestStatus.value.loadErr = true

    lastNoteText = previouseText

    return false
}

const onInput = () => {
    if (!props.autoSave) {
        return
    }

    if (timeoutId) {
        clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => {
        saveNote()
    }, UPDATE_TIMEOUT)
}

const deleteModalButtonClicked = async (index: number) => {
    if (index) {
        await deleteNote(true)
    }

    deleteModalOpen.value = false
}

const onUpdateProductNote: Handler<Note> = (updatedNote: Note) => {
    note.value = { ...note.value, ...updatedNote }
    noteText.value = note.value.note
    lastNoteText = noteText.value
}

onMounted(async () => {
    loading.value = true

    if (props.noteData) {
        note.value = {
            note: props.noteData.note,
            lastModified: props.noteData.lastModified,
            lastEditor: props.noteData.lastEditor,
            isSuccessful: true,
        }
        noteText.value = note.value!.note
        lastNoteText = noteText.value
    } else if (props.isCartService) {
        const response = (await loadNote(`${props.productAbstractId}`)) as ApiResponse | Note

        if (!('error' in response && response?.error)) {
            note.value = response as Note
            noteText.value = note.value!.note
            lastNoteText = noteText.value
        }
    } else {
        if (!notesStore.initialized) {
            await loadNotes()
            notesStore.initialize(notes.value)
        }

        if (notesStore.hasId(`${props.productAbstractId}`)) {
            const response = (await loadNote(`${props.productAbstractId}`)) as ApiResponse | Note

            if ('error' in response && response?.error) {
                requestStatus.value.failedText = $t('Product.note_not_loaded')
                requestStatus.value.loadErr = true
            } else {
                note.value = response as Note
                noteText.value = note.value!.note
                lastNoteText = noteText.value
            }
        }
    }

    loading.value = false
    emit('loaded')

    if (props.tileView) {
        $emitter.$on(`updateProductNote_${props.productAbstractId}`, onUpdateProductNote)
    }
})

onBeforeUnmount(() => {
    if (props.tileView) {
        $emitter.$off(`updateProductNote_${props.productAbstractId}`, onUpdateProductNote)
    }
})

defineExpose({
    loading,
    noteInput,
    note,
    saveNote,
    deleteNote,
})
</script>

<style lang="scss" scoped>
.note {
    &-input {
        flex: 1;
        width: 100%;
        height: rem(55);
        resize: none;

        @include helper-font-weight(regular);
        @include helper-font-size(default);
        @include helper-font-line-height(4);
        @include helper-color(text-title);

        &::placeholder {
            @include helper-color(text-secondary);
        }
    }

    &-status {
        position: relative;
        min-height: rem(18);
    }

    &-form {
        position: relative;
        width: 100%;
        padding: sp(xs);
        margin-bottom: sp(xs);
        display: flex;
        flex-direction: column;

        @include helper-border();
        @include helper-border-radius($setting-border-radius-input);

        align-items: flex-start;

        &.is-focused {
            @include helper-border-color(corporate-blue);
        }

        &.is-disabled {
            pointer-events: none;

            @include helper-color-bg(light-gray);

            .note-input {
                background-color: inherit;
            }
        }
    }

    &-container {
        width: 100%;

        &.is-tileview {
            .note-form {
                @include helper-border($setting-color-gray-1);

                height: rem(158);
                margin-bottom: rem(15);

                &.is-focused {
                    @include helper-border-color(corporate-blue);
                }
            }

            .note-input {
                padding-bottom: sp(xxs);
            }

            .note-status {
                min-height: rem(54);
            }

            :deep(.top-feedback) {
                position: static;

                .top-feedback__save:not(:empty) {
                    display: flex;
                    align-items: center;
                    min-height: rem(54);
                }
            }
        }
    }

    &-limit {
        width: 100%;
        margin-top: auto;
        text-align: right;

        @include helper-font-weight(regular);
        @include helper-font-size(small);
        @include helper-color(text-secondary);
    }

    &-created {
        @include helper-font-size(small);
        @include helper-color(text-title);
    }
}

:deep(.top-feedback) {
    position: absolute;
    right: 0;
    height: auto;

    .top-feedback__text {
        @include helper-font-size(small);
        @include helper-font-line-height(default);
    }
}

.note-modal {
    &__title-container {
        width: 100%;
        margin-bottom: sp(s);
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: flex-start;
    }

    &__title {
        @include helper-font-weight(medium);
        @include helper-font-size(3);
        @include helper-font-line-height(2);
        @include helper-color(text-titel);
    }

    &__text {
        @include helper-font-weight(regular);
        @include helper-font-size(default);
        @include helper-font-line-height(3);
        @include helper-color(text-titel);
    }

    &__last-modified {
        margin-left: auto;
        margin-bottom: sp(xxs);

        @include helper-font-weight(regular);
        @include helper-font-size(small);
        @include helper-font-line-height(5);
        @include helper-color(text-secondary);
    }

    :deep(.modal-box__close) {
        display: none;
    }

    :deep(.modal-box) {
        min-height: 0;
    }
}
</style>
