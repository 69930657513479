import type { ApiResponse } from '~/composables/types/api/apiResponse'
import handleError from '~/composables/api/handleResponse'
import type { Note, NoteResponse, NoteUpdateParams } from '~/composables/types/api/searchDiscover/product'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function updateNote(params: NoteUpdateParams): Promise<Note | ApiResponse> {
    const url = `/abstract-products-notes`
    const { fetchSdApi } = fetchOrderDetermination()

    const noteData = {
        data: {
            type: `abstract-products-notes`,
            attributes: {
                id_product_abstract: params.noteId.toString(),
                note: params.note,
            },
        },
    }

    try {
        const response = (await fetchSdApi(url, {
            method: 'POST',
            body: noteData,
        })) as NoteResponse

        return {
            note: response.data?.attributes.note,
            lastModified: response.data?.attributes.lastModified,
            lastEditor: response.data?.attributes.lastEditor,
            isSuccessful: response.data?.attributes.isSuccessful,
        }
    } catch (error) {
        return handleError(error)
    }
}
