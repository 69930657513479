import handleError from '~/composables/api/handleResponse'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function deleteNote(noteId: string): Promise<void> {
    const url = `/abstract-products-notes/${noteId}`
    const { fetchSdApi } = fetchOrderDetermination()

    try {
        await fetchSdApi(url, {
            method: 'DELETE',
        })
    } catch (error: any) {
        handleError(error)
    }
}
