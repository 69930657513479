export const useNotesStore = defineStore('noteAbstractIds', {
    state: () => ({
        ids: [] as string[],
        initialized: false,
    }),

    actions: {
        initialize(responseIds: string[] | number[]) {
            this.ids = responseIds.map(String)
            this.initialized = true
        },

        insert(value: string) {
            this.ids.push(value)
        },

        hasId(id: string): boolean {
            return this.ids.length ? this.ids.includes(id) : false
        },

        removeId(value: string) {
            const index = this.ids.indexOf(value)
            if (index !== -1) {
                this.ids.splice(index, 1)
            }
        },
    },
})
