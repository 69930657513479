import type { Note, NoteResponse } from '~/composables/types/api/searchDiscover/product'
import handleError from '~/composables/api/handleResponse'
import type { ApiResponse } from '~/composables/types/api/apiResponse'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function (noteId?: string): Promise<Note | ApiResponse> {
    const url = noteId ? `/abstract-products-notes/${noteId}` : `/abstract-products-notes`
    const { fetchSdApi } = fetchOrderDetermination()

    try {
        const response = (await fetchSdApi(url)) as NoteResponse

        return {
            note: response.data?.attributes.note,
            lastModified: response.data?.attributes.lastModified,
            lastEditor: response.data?.attributes.lastEditor,
            isSuccessful: response.data?.attributes.isSuccessful,
        }
    } catch (error) {
        return handleError(error)
    }
}
